<template>
  <div class="entrustChild">
    <div class="main">
      <div class="main-l" v-if="oIndex == 0">
        <div class="video">
          <div id="box" v-if="url" width="100%" height="100%"></div>
          <div class="lock" v-else>
            <div class="img"><img src="./img/lock.png" alt="" /></div>
            <div class="txt">无权限观看哦~</div>
          </div>
        </div>
      </div>
      <div class="main-r" v-else>
        <div class="myInfo">
          <div class="item">
            <div class="item-l">家长姓名</div>
            <div class="item-r">{{ postData.parentsName }}</div>
          </div>
          <div class="item">
            <div class="item-l">家长电话</div>
            <div class="item-r">{{ postData.parentsPhone }}</div>
          </div>
          <div class="item">
            <div class="item-l">孩子姓名</div>
            <div class="item-r">{{ postData.childName }}</div>
          </div>
          <div class="item">
            <div class="item-l">托幼时间</div>
            <div class="item-r">
              {{ returnTime(postData.startTime) }}-{{
                returnTime(postData.endTime)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in postData"
        :key="index"
        @click="selectShipin(item, index)"
      >
        <div :class="['oItem', { active: index == oActive }]">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="dialog" v-if="showDialog2">
      <div class="box">
        <div class="title">因政务服务器带宽限制</div>
        <div class="p">请重新进入页面</div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRegister } from "@/utils/common";
import wx from "weixin-js-sdk";
import { lookPower, getMonitoringListByScheduleIdUrl } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "entrustChild",
  data() {
    return {
      oActive: 0,
      showDialog2: false,
      oIndex: 0,
      postData: "",
      player: "",
      scheduleId: "",
      detailid: "",
      url: "",
      timer: null,
      tabList: [{ label: "监控画面" }, { label: "我的信息" }],
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async mounted() {
    this.player = null;
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
    this.setTime();
    if (await toRegister(this.$route.path, this.$route.query, "场馆预约")) {
      await this.getMonitoringListByScheduleId();
    }
    this.init();
  },
  created() {
    this.scheduleId = this.$route.query.scheduleId;
    const { detailid } = this.$route.query;
    if (detailid) {
      this.detailid = detailid;
    }
  },
  beforeDestroy() {
    if (document.getElementById("box")) {
      document.getElementById("box").innerHTML = "";
    }
    this.player = null;
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    setTime() {
      this.timer = setTimeout(() => {
        this.showDialog2 = true;
        if (document.getElementById("box")) {
          document.getElementById("box").innerHTML = "";
        }
        this.player = null;
        this.url = "";
      }, 1000 * 60 * 5);
    },
    selectShipin(item, index) {
      this.oActive = index;
      if (document.getElementById("box")) {
        document.getElementById("box").innerHTML = "";
      }
      this.player = null;
      this.url = this.postData && this.postData[index].url;
      this.init();
    },
    async getMonitoringListByScheduleId() {
      let params = {
        userId: this.userId,
        scheduleId: this.scheduleId || 19,
        detailid: this.detailid,
      };
      let res = await this.$axios.get(getMonitoringListByScheduleIdUrl, {
        params,
      });
      if (res.code === 200) {
        this.postData = res.data;
        this.url = this.postData && this.postData[0].url;
        // console.log(this.postData, "===09");
        if (this.postData == null || this.postData == "") {
          this.$router.replace({
            name: "entrustChildList",
          });
        }
      }
    },
    init() {
      let _this = this;
      document.getElementById("box").innerHTML = `<div
            id="J_prismPlayer"
            width="100%"
            height="100%"
            class="prism-player"
          ></div>`;
      this.player = new window.Aliplayer(
        {
          id: "J_prismPlayer", // 容器id
          source: _this.url, // 视频url
          width: "100%", // 播放器宽度
          height: "100%", // 播放器高度
          autoplay: true,
          isLive: true,
          rePlay: false,
          playsinline: true,
          preload: true,
          controlBarVisibility: "hover",
          useH5Prism: true,
        },
        function (player) {
          console.log("播放器创建了");
        }
      );
    },
    returnTime(time) {
      if (time) {
        return moment(time).format("HH:mm");
      }
    },
    // selectMem(item, index) {
    //   this.oIndex = index;
    //   if (index == 1) {
    //     this.player = null;
    //     document.getElementById("box").innerHTML = "";
    //   } else {
    //     this.$nextTick(() => {
    //       this.init();
    //     });
    //   }
    // },
    async getPower() {
      let params = {
        userId: this.userId,
        scheduleId: this.scheduleId,
        detailid: this.detailid,
      };
      let res = await this.$axios.get(lookPower, { params });
      if (res.code === 200) {
        this.postData = res.data;
        // console.log(this.postData, "===09");
        if (this.postData == null || this.postData == "") {
          this.$router.replace({
            name: "entrustChildList",
          });
        }
      } else {
      }
    },
    // async getPartyList() {
    //   let res = await this.$axios.get(lookPower, {
    //     params: {
    //       tenantId: this.tenantId,
    //       userId: this.userId,
    //       status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
    //       topicTypes: "7,8,9,10,11", // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
    //       curPage: this.curPage,
    //       pageSize: this.pageSize,
    //     },
    //   });
    //   if (res.code === 200) {
    //     if (this.curPage >= res.data.pages) {
    //       this.finished = true;
    //     } else {
    //       this.finished = false;
    //       this.$refs.list.loading = false;
    //     }
    //     this.finishedText = "没有更多了";
    //     if (res.data.records.length === 0) {
    //       this.listData = [];
    //       this.finishedText = "";
    //       return;
    //     }
    //     res.data.records.forEach((item) => {
    //       // 如果请求数据不重复，则添加进listData
    //       this.listData.push(item);
    //     });
    //   } else {
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.entrustChild {
  height: 100vh;
  overflow: hidden;
  .tab {
    display: flex;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 10000;
    .item {
      flex: 1;
      text-align: center;
      padding: 28px 0;
      font-size: 28px;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
    }
    .active {
      position: relative;
      font-weight: 700;
      &::after {
        content: "";
        position: absolute;
        width: 64px;
        height: 8px;
        background: #007eff;
        border-radius: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .main {
    .main-l {
      .video {
        width: 100%;
        height: 400px;
        #box {
          width: 100%;
          height: 100%;
        }
        border-radius: 32px;
        box-sizing: border-box;
        position: relative;
        #J_prismPlayer video {
          border-radius: 32px;
        }
        video {
          box-sizing: border-box;
          border-radius: 32px;
        }
        .lock {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 999;
          top: 0;
          left: 0;
          border-radius: 32px;
          background: rgba(0, 0, 0, 0.2);
          .name {
            position: absolute;
            top: 22px;
            left: 26px;
            font-size: 28px;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
          }
          .img {
            width: 48px;
            height: 48px;
            margin: 140px auto 18px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            font-size: 40px;
            text-align: center;
            font-weight: 600;
            color: #ffffff;
            line-height: 56px;
          }
        }
      }
    }
    .main-r {
      .myInfo {
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        border-radius: 16px;
        padding: 0 14px;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px 28px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 44px;
        }
      }
    }
  }
  .list {
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 25%;
      margin-bottom: 22px;
      flex-shrink: 0;
      .oItem {
        width: 148px;
        margin: 0 auto;
        height: 60px;
        background: #d7ece1;
        border-radius: 8px;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #2a7271;
        line-height: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .active {
        background: #2a7271;
        color: #d4e2d1;
      }
    }
  }
  .dialog {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .box {
      width: 588px;
      height: 312px;
      background: linear-gradient(180deg, #a4e6e6 0%, #ffffff 100%);
      border-radius: 8px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-top: 110px;
      box-sizing: border-box;
      &::before {
        top: -40px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        width: 368px;
        height: 114px;
        background: url("./img/info.png") no-repeat;
        background-size: 100% 100%;
      }
      .title {
        font-size: 28px;
        text-align: center;
        font-weight: 700;
        color: #333333;
        line-height: 76px;
      }
      .p {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        color: #333333;
        line-height: 76px;
      }
    }
  }
}
.entrustChild {
}
</style>
